export const countries = [
  {
    value: 'ae',
    icon: 'united-arab-emirates',
    imageUrl: '/countries/akn-country-UAE.svg',
    localizable: true,
    public: true,
    code: '+971',
    link: '/en-ae/',
    en: {
      link: '/en-ae/',
      currency: 'AED',
      label: 'UAE',
      city: 'Dubai'
    },
    ar: {
      link: '/ar-ae/',
      currency: 'د.إ',
      label: 'الإمارات',
      city: 'دبي'
    }
  },
  {
    value: 'qa',
    icon: 'country-qatar',
    imageUrl: '/countries/akn-country-Qatar.svg',
    localizable: true,
    public: true,
    code: '+974',
    link: '/ar-qa/',
    en: {
      link: '/en-qa/',
      currency: 'QAR',
      label: 'Qatar',
      city: 'Doha'
    },
    ar: {
      link: '/ar-qa/',
      currency: 'ر.ق',
      label: 'دولة قطر',
      city: 'الدوحة'
    }
  },
  {
    value: 'sa',
    icon: 'saudi-arabia',
    imageUrl: '/countries/akn-country-SaudiArabia.svg',
    localizable: true,
    public: true,
    code: '+966',
    link: '/ar-sa/',
    en: {
      link: '/en-sa/',
      currency: 'SAR',
      label: 'KSA',
      city: 'Riyadh'
    },
    ar: {
      link: '/ar-sa/',
      currency: 'ر.س',
      label: 'السعودية',
      city: 'الرياض'
    }
  },
  {
    value: 'om',
    apiLabel: 'OMAN',
    icon: 'oman',
    imageUrl: '/countries/akn-country-Oman.svg',
    localizable: true,
    public: true,
    code: '+968',
    link: '/ar-om/',
    en: {
      link: '/en-om/',
      currency: 'OMR',
      label: 'Oman',
      city: 'Muscat'
    },
    ar: {
      link: '/ar-om/',
      currency: 'ر.ع',
      label: 'سلطنة عمان',
      city: 'مسقط'
    }
  },
  {
    value: 'kw',
    icon: 'kuwait',
    imageUrl: '/countries/akn-country-Kuwait.svg',
    localizable: true,
    public: true,
    code: '+965',
    link: '/ar-kw/',
    en: {
      link: '/en-kw/',
      currency: 'KWD',
      label: 'Kuwait',
      city: 'Kuwait City'
    },
    ar: {
      link: '/ar-kw/',
      currency: 'د.ك',
      label: 'الكويت',
      city: 'مدينة الكويت'
    }
  },
  {
    value: 'bh',
    icon: 'bahrain',
    imageUrl: '/countries/akn-country-Bahrain.svg',
    localizable: true,
    public: true,
    code: '+973',
    link: '/ar-bh/',
    en: {
      link: '/en-bh/',
      currency: 'BHD',
      label: 'Bahrain',
      city: 'Manama'
    },
    ar: {
      link: '/ar-bh/',
      currency: 'د.ب',
      label: 'البحرين',
      city: 'المنامة'
    }
  }
];

export const countryCurrencyMap = {
  ae: 'aed',
  qa: 'qar',
  sa: 'sar',
  om: 'omr',
  kw: 'kwd',
  bh: 'bhd'
};

export const countryShortCodeMap = {
  ae: 'uae',
  qa: 'qtr',
  sa: 'ksa',
  bh: 'bahrain',
  om: 'oman',
  kw: 'kwt',
};

const { LocaleUrlStrategy } = require('@akinon/next/localization');
const { countryCurrencyMap, countries } = require('countries');
const { ROUTES } = require('@theme/routes');

const locales = [];

if (countries) {
  countries.forEach((country) => {
    if (country.localizable) {
      locales.push({
        label: 'EN',
        value: `en-${country.value}`,
        localePath: 'en',
        apiValue: `en-${country.value}`,
        rtl: false
      });
      locales.push({
        label: 'AR',
        value: `ar-${country.value}`,
        localePath: 'ar',
        apiValue: `ar-${country.value}`,
        rtl: true
      });
    }
  });
}

const commerceUrl = encodeURI(process.env.SERVICE_BACKEND_URL ?? 'default');

/** @type {import('@akinon/next/types').Settings} */
module.exports = {
  commerceUrl,
  commonProductAttributes: [
    { translationKey: 'color', key: 'color' },
    { translationKey: 'size', key: 'size' }
  ],
  localization: {
    locales: locales,
    currencies: [
      {
        label: 'USD',
        code: 'usd'
      },
      {
        label: 'AED',
        code: 'aed'
      },
      {
        label: 'SAR',
        code: 'sar'
      },
      {
        label: 'BHD',
        code: 'bhd'
      },
      {
        label: 'OMR',
        code: 'omr'
      },
      {
        label: 'KWD',
        code: 'kwd'
      },
      {
        label: 'QAR',
        code: 'qar'
      }
    ],
    defaultLocaleValue: 'en-ae',
    localeUrlStrategy: LocaleUrlStrategy.ShowAllLocales,
    redirectToDefaultLocale: true,
    defaultCurrencyCode: 'aed',
    getActiveCurrencyCode: ({ req, locale, defaultCurrencyCode }) => {
      const [, countryCode] = locale && locale.split('-');

      return countryCurrencyMap[countryCode] ?? defaultCurrencyCode;
    }
  },
  rewrites: [
    {
      source: ROUTES.AUTH,
      destination: '/auth'
    },
    {
      source: ROUTES.BASKET,
      destination: '/basket'
    },
    {
      source: ROUTES.ACCOUNT_ORDERS,
      destination: '/account/orders'
    }
  ],
  redis: {
    defaultExpirationTime: 900 // 15 min
  },
  customNotFoundEnabled: false,
  checkout: {
    iframeExcludedPaymentOptions: ['tappay', 'saved_card', 'funds-transfer'],
    extraPaymentTypes: ['loyalty_money'],
  },
  resetBasketOnCurrencyChange: false
};
